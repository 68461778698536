import React from "react"
import _ from "lodash"
import {css} from "@emotion/core"

export default ({tagList}) => {
  let tags = []

  const style = css`
    margin-top: .5rem;
    
    & > span {
      display: inline-block;
      margin-right: .5rem;
      padding: .2rem .5rem;
      color: white;
      font-size: 13px;
      letter-spacing: .05rem;
      
      &.tag-selling-1 {
        background: #51CDA9;
      }
      &.tag-selling-2 {
        background: #FB6B8F;
      }
      &.tag-selling-3 {
        background: #3F97D1;
      }
      &.tag-selling-4 {
        background: #F5A460;
      }
      &.tag-selling-5 {
        background: #A455C1;
      }
      &.tag-selling-6 {
        background: #E15F5F;
      }
    }
  `

  if (_.has(tagList, "sellingStatus")) {
    switch (_.get(tagList, "sellingStatus")) {
      case "1":
        tags.push({className: "tag-selling-1", value: "通常商品"})
        break
      case "2":
        tags.push({className: "tag-selling-2", value: "予約商品"})
        break
      case "3":
        tags.push({className: "tag-selling-3", value: "受注商品"})
        break
      case "4":
        tags.push({className: "tag-selling-4", value: "受注商品（後払い）"})
        break
      case "5":
        tags.push({className: "tag-selling-5", value: "会場引換商品"})
        break
      case "6":
        tags.push({className: "tag-selling-6", value: "抽選販売"})
        break  
    }
  }

  return (
    <div css={style}>{tags.map(tag => (
      <span key={tag.className} className={tag.className}>{tag.value}</span>
    ))}</div>
  )
}
